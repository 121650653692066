<script setup lang="ts">
export interface PriceProps {
  price: string | number
  taxIncluded?: boolean
  interval?: PriceInterval
  size?: 'small' | 'medium' | 'large'
  bold?: boolean
  showSuffix?: boolean
}

const props = withDefaults(
  defineProps<PriceProps>(),
  {
    taxIncluded: false,
    interval: undefined,
    size: 'small',
    bold: false,
    showSuffix: true,
  },
)

const formatted = computed(() => {
  const price = formatNOK(Number(props.price))

  let label = price
  let text = price
  if (props.interval) {
    label += ` per ${props.interval === 'yearly' ? 'år' : 'måned'}`
    text += `/${props.interval === 'yearly' ? 'år' : 'mnd.'}`
  }

  let suffix: string

  if (props.taxIncluded) {
    label += ' inkludert '
    suffix = ' inkl. '
  }
  else {
    label += ' ekskludert '
    suffix = ' ekskl. '
  }

  label += 'merverdiavgift'
  suffix += 'mva.'

  return { label, text, suffix }
})
</script>

<template>
  <strong
    v-if="size === 'small'"
    class="font-body text-md" :class="bold ? 'font-semibold' : 'font-normal'"
  >
    <span data-dd-privacy="mask" v-text="formatted.text" />
    <span v-if="showSuffix" v-text="formatted.suffix" />
  </strong>
  <div v-else class="flex flex-col items-center" :aria-label="formatted.label">
    <strong data-dd-privacy="mask" class="font-medium" aria-hidden="true" :class="{ 'text-h3': size === 'large', 'text-h5 font-semibold': size === 'medium' }" v-text="formatted.text" />
    <span v-if="showSuffix" :class="{ 'text-h5 font-light': size === 'large', 'text-base': size === 'medium' }" aria-hidden="true" v-text="formatted.suffix" />
  </div>
</template>
